<template>
  <div class="mobile">
    <div class="banner">
      <div class="bannerWrap1">
        <h3>OnlyClass</h3>
        <h1>昂立直播平台</h1>
        <h5>让课堂更精彩</h5>

        <vs-button gradient @click="download" class="btn">
          <img
            src="@/assets/android_mobile.svg"
            alt=""
            width="20"
            v-if="this.$device.android"
          />
          <img
            src="@/assets/apple.svg"
            alt=""
            width="20"
            v-if="this.$device.ios"
          />
          <span>立即下载</span>
        </vs-button>
        <h6>当前版本号：{{ this.$commonData.versionList[0].version }}</h6>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({}),
  methods: {
    download() {
      if (this.$device.android) {
        const url = this.$commonData.urls.android
        if (this.$device.wechat) {
          this.$copyText(url).then(() => {
            alert('下载链接已复制，请直接粘贴到外部浏览器中下载！')
          })
        } else {
          window.location.href = url
        }
      }
      if (this.$device.ios) {
        const url = this.$commonData.urls.ios
        window.location.href = url
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.mobile {
  .banner {
    height: 100vh;
    background-image: url('../assets/bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    .bannerWrap1 {
      color: #fff;
      padding-bottom: 180px;
      display: flex;
      flex-direction: column;
      align-items: center;
      h3 {
        font-weight: 100;
      }
      .btn {
        width: 80%;
        span {
          padding: 0 10px;
        }
      }
    }
  }
}
</style>
